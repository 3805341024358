<template>
  <div id="app">
    <nav class="navbar fixed-top navbar-expand-md navbar-dark">
      <div class="container">
        <a href="#home" class="navbar-brand"><img src="assets/img/logo_white.png" alt="KeyonBIT logo"></a><button type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation" id="hamburger_btn" class="navbar-toggler"><span class="navbar-toggler-icon"></span></button>
        <div id="navbarToggler" class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto mt-lg-0">
            <li class="nav-item active"><a href="#home" class="nav-link">Home</a></li>
            <li class="nav-item"><a href="#about" class="nav-link">About us</a></li>
            <li class="nav-item"><a href="#features" class="nav-link">Features</a></li>
            <li class="nav-item"><a href="https://datafive.co.kr/" class="nav-link">AI바우처사업</a></li>
            <li class="nav-item">
              <div data-toggle="buttons" class="btn-group btn-group-toggle">
                <label class="btn btn-lang active" v-on:click="locale='ko'">
                  <input type="radio" name="options" id="korean" autocomplete="off" checked="checked" value="ko"> KR
              </label>
                <label class="btn btn-lang" v-on:click="locale='en'">
                  <input type="radio" name="options" id="english" autocomplete="off" value="en"> EN
              </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <main>
      <section id="home">
        <div class="center">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6">
                <h1><img src="assets/img/logo_blue.png" alt="KeyonBIT logo"></h1>
                <p class="lead">{{ $t('top_title') }}</p>
                <p>{{ $t('top_desc') }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about">
        <div class="container">
          <div class="row">
            <h4 class="col-12">Value we create</h4>
            <div class="col-12 col-md-4">
              <div class="item">
                <img src="assets/img/img_simple.png">
                <h2>Simplicity</h2>
                <p>{{ $t('simple_desc') }} </p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="item">
                <img src="assets/img/img_reliability.png">
                <h2>Reliability</h2>
                <p>{{ $t('reliable_desc') }}</p>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="item">
                <img src="assets/img/img_speed.png">
                <h2>Speed</h2>
                <p>{{ $t('speed_desc') }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features">
        <div class="container">
          <h4>Solution Features</h4>
          <div class="item">
            <div class="img-align">
              <h2>{{ $t('high_level_title') }}</h2>
              <img src="assets/img/img_auto.png">
            </div>
            <p>{{ $t('high_level_desc') }}</p>
          </div>
          <div class="item">
            <div class="img-align">
              <h2>{{ $t('deep_title') }}</h2>
              <img src="assets/img/img_deeplearning.png">
            </div>
            <p>{{ $t('deep_desc') }}</p>
          </div>
          <div class="item">
            <div class="img-align">
              <h2>{{ $t('saas_title') }}</h2>
              <img src="assets/img/img_saas.png">
            </div>
            <p>{{ $t('saas_desc') }}</p>
          </div>
        </div>
      </section>
      <section id="contact">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h4>contact</h4>
              <p>{{ $t('contact_desc') }}</p>
              <p class="mail">admin@keyonbit.com</p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <ul class="footer-menu">
              <li><a href="#home">Home</a></li>
              <li><a href="#about">About us</a></li>
              <li><a href="#features">Features</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          <div class="col-sm-6 col-md-4 offset-md-2 col-lg-3 info map">
            <div id="map" style="height: 100%"></div>
          </div>
          <div class="col-sm-6 col-md-4 info">
            <h5>(주)키온비트</h5>
            <ul class="list-unstyled text-small">
              <li><img src="assets/img/icon_mappoint_new.png">
                {{ $t('address')}}
              </li>
              <li><img src="assets/img/icon_envelope_new.png">admin@keyonbit.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="container">
          <div class="row">
            <div class="col-3"><img src="assets/img/logo.png" alt="KeyonBIT logo white"></div>
            <span class="col-9">© 2020  Copyrigts KeyonBIT</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<i18n>
{
  "en": {
    "top_title": "Cloud Security Solution",
    "top_desc": "AI solutions that detects malware within binary files in cloud systems, automating the tedious and time-consuming tasks required by experienced malware analysts",
    "simple_desc": "Providing strong security with simple controls, instead of adding another complexity to a complex cloud ",
    "reliable_desc": "Improving reliability through continual deep learning including recent malware",
    "speed_desc": "Extracting the features of the application file to speeds up the analysis.",
    "high_level_title": "Automated high-level malware analysis ",
    "high_level_desc": "Automating the tedious and time-consuming tasks required by experienced malware analysts",
    "deep_title": "Deep learning-based static analysis",
    "deep_desc": "Saving resources for malware detection without an execution environment.",
    "saas_title": "SaaS (Software as a service)",
    "saas_desc": "High scalability, reducing billing and maintenance burden for usage. ",
    "contact_desc": "Please feel free to email us if any inquiries.",
    "address": "126Ho, 12F, 428, Seolleung-ro, Gangnam-gu, Seoul, Republic of Korea"
  },
  "ko": {
    "top_title": "클라우드 보안 솔루션",
    "top_desc": "클라우드 시스템 상의 애플리케이션 파일에 악성코드가  포함되어 있는지를 분석하는 AI기반의 솔루션 입니다.  클라우드에 침투할 때 가장 많이 이용하는 경로인 클라우드 기반  애플리케이션 파일을 분석하여 클라우드 시스템의 보안을 강화합니다.",
    "simple_desc": "복잡한 클라우드에 또 하나의 복잡함을 더하기 보다는, 단순한 제어로 깊이 있는 보안을 제공합니다.",
    "reliable_desc": "최근에 배포된 악성코드까지 분석이 가능하도록 딥러닝의  지속적인 훈련을 기반으로한 모델을 적용하여, 신뢰성을 높입니다.",
    "speed_desc": "분석속도를 높이기 위하여, 애플리케이션 파일의 특징을 추출하여 분석합니다.",
    "high_level_title": "고난이도 악성 코드 분석작업 자동화",
    "high_level_desc": "단순 반복적인 숙련자의 악성코드 분석작업을 자동화하여,  많은 양의 좀 더 빨리 분석 가능합니다.",
    "deep_title": "딥러닝 기반의 정적분석",
    "deep_desc": "실행환경 필요 없이 분석 작업이 수행되어, 분석에 필요한 리소스를 절약할수 있습니다.",
    "saas_title": "SaaS 서비스로 제공",
    "saas_desc": "시스템 기반의 솔루션뿐만 아니라, SaaS 로도 제공되어, 높은 확장성이 보장되며, 고객은 사용한 만큼의 과금과 유지보수에 대한 부담을 줄일 수 있음",
    "contact_desc": "(주)키온비트에 궁금하신 사항이 있으신 경우, 다음 주소로 이메일을 보내주시면 빠른 시일 내에 답변 드리겠습니다.",
    "address": "서울특별시 강남구 선릉로 428, 12층 126호"
  }
}
</i18n>

<script>
export default {
  name: 'App',
  data () { return { locale: 'ko' } },
  watch: {
    locale (val) {
      this.$i18n.locale = val
    }
  }
}
</script>
